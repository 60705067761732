export const rootUrl = "https://attendium.com";
export const productionMode = true;
export const packageName = "attendium-frontend";
export const packageVersion = "2.0.3475";
export const capacitorMode = false;
export const restrictBilling = false;
export const scannerEnabled = false;
export const defaultLocale = "en";
export const locales = [{"code":"en","selectable":true},{"code":"sv","selectable":true},{"code":"es","selectable":true},{"code":"de","selectable":true}];
export const sellerCountryCode = "SE";
export const supportEmailAddress = "support@attendium.com";
export const appReviewTarget = "manager";
export const appStoreId = 432770595;
export const appStoreUrl = "https://apps.apple.com/us/app/guest-list-app-attendium/id432770595";
export const googlePlayUrl = "https://play.google.com/store/apps/details?id=com.attendium.mobile.android";
export const googleAnalyticsMeasurementId = "G-RG5XC3WKZ1";
export const googleAdsAccountId = "AW-777609432";
export const metaPixelId = null;
export const healthCheckPath = "healthCheck";
export const sentryDsn = "https://37e90cd447d146d88629c87963dc79cf@sentry.io/191340";
export const sentryTunnelUrl = "https://api.attendium.com/sentry/envelopes";
export const sentryCspReportUri = "https://o14508.ingest.us.sentry.io/api/191340/security/?sentry_key=37e90cd447d146d88629c87963dc79cf";
export const screenRecorderEnabled = true;