import * as stylex from "@stylexjs/stylex";
import { kebabCase } from "change-case";
import { SafeAreaInsets } from "./safeAreaInsets.ts";
import { Theme } from "./Theme.ts";
import {
  safeAreaInsetVars,
  themeVars,
  virtualKeyboardVars,
} from "./variables.stylex.ts";

export function stringifyCss(css?: { [key: string]: any }) {
  return (
    css &&
    Object.entries(css)
      .map(
        ([key, value]) =>
          `${key.startsWith("--") ? key : kebabCase(key)}: ${value}`,
      )
      .join(";")
  );
}

function getStylexVarName(stylexVar: string) {
  return stylexVar.substring(4, stylexVar.length - 1);
}

export const themeVarsCss = (theme: Theme) =>
  Object.entries(theme).reduce((accumulator, [name, value]) => {
    accumulator[getStylexVarName(themeVars[name])] =
      typeof value === "number" && name !== "lineHeight" ? `${value}px` : value;
    return accumulator;
  }, {});

export const virtualKeyboardVarsCss = ({ height }: { height: number }) => ({
  [getStylexVarName(virtualKeyboardVars.height)]: `${height}px`,
});

export const baseCss = {
  background: themeVars.backgroundColor,
  fontFamily: themeVars.fontFamily,
  fontWeight: "normal",
  fontSize: themeVars.fontSize,
  color: themeVars.textColor,
  lineHeight: themeVars.lineHeight,
};

const effectiveLineHeight = `calc(${themeVars.fontSize} * ${themeVars.lineHeight})`;

export const commonStyles = stylex.create({
  themeVars: (
    mainColor,
    successColor,
    backgroundColor,
    alternativeBackgroundColor,
    textColor,
    errorColor,
    warningColor,
    separatorColor,
    dark,
    spacing,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    maxWidth,
    fontFamily,
    noteColor,
    fontSize,
    smallFontSize,
    lineHeight,
    roundCornerBorderRadius,
    iconSize,
    weakSeparatorColor,
    weakestSeparatorColor,
    titleBorderSpacing,
    backgroundColorRgb,
  ) => ({
    [themeVars.mainColor]: mainColor,
    [themeVars.successColor]: successColor,
    [themeVars.backgroundColor]: backgroundColor,
    [themeVars.alternativeBackgroundColor]: alternativeBackgroundColor,
    [themeVars.textColor]: textColor,
    [themeVars.errorColor]: errorColor,
    [themeVars.warningColor]: warningColor,
    [themeVars.separatorColor]: separatorColor,
    [themeVars.dark]: dark,
    [themeVars.spacing]: `${spacing}px`,
    [themeVars.paddingTop]: `${paddingTop}px`,
    [themeVars.paddingRight]: `${paddingRight}px`,
    [themeVars.paddingBottom]: `${paddingBottom}px`,
    [themeVars.paddingLeft]: `${paddingLeft}px`,
    [themeVars.maxWidth]: `${maxWidth}px`,
    [themeVars.fontFamily]: fontFamily,
    [themeVars.noteColor]: noteColor,
    [themeVars.fontSize]: `${fontSize}px`,
    [themeVars.smallFontSize]: `${smallFontSize}px`,
    [themeVars.lineHeight]: lineHeight,
    [themeVars.roundCornerBorderRadius]: `${roundCornerBorderRadius}px`,
    [themeVars.iconSize]: `${iconSize}px`,
    [themeVars.weakSeparatorColor]: weakSeparatorColor,
    [themeVars.weakestSeparatorColor]: weakestSeparatorColor,
    [themeVars.titleBorderSpacing]: `${titleBorderSpacing}px`,
    [themeVars.backgroundColorRgb]: backgroundColorRgb,
  }),

  safeAreaInsetVars: (insets: SafeAreaInsets) => ({
    [safeAreaInsetVars.top]: `${insets.top}px`,
    [safeAreaInsetVars.right]: `${insets.right}px`,
    [safeAreaInsetVars.bottom]: `${insets.bottom}px`,
    [safeAreaInsetVars.left]: `${insets.left}px`,
  }),

  virtualKeyboardVarsStyle: (height: number) => ({
    [virtualKeyboardVars.height]: height,
  }),

  base: baseCss,
  pageTitle: {
    color: themeVars.headerColor,
    fontWeight: "500",
    fontSize: 28,
  },
  roundedBordered: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: themeVars.roundCornerBorderRadius,
    borderColor: themeVars.separatorColor,
  },
  groupTitle: {
    color: themeVars.headerColor,
    fontWeight: "500",
    fontSize: 17,
  },
  strong: {
    fontWeight: "500",
  },
  note: {
    fontSize: themeVars.smallFontSize,
    color: themeVars.noteColor,
  },
  a: {
    color: themeVars.mainColor,
    cursor: "pointer",
  },
  h1: {
    color: themeVars.headerColor,
    fontWeight: "500",
    fontSize: 44,
  },
  h2: {
    color: themeVars.headerColor,
    fontWeight: "500",
    fontSize: 30,
  },
  h3: {
    color: themeVars.headerColor,
    fontWeight: "500",
    fontSize: 24,
  },
  h4: {
    color: themeVars.headerColor,
    fontWeight: "500",
    fontSize: 20,
  },
  padded: {
    paddingTop: themeVars.paddingTop,
    paddingRight: themeVars.paddingRight,
    paddingBottom: themeVars.paddingBottom,
    paddingLeft: themeVars.paddingLeft,
  },
  doublePadded: {
    paddingTop: `calc(2 * ${themeVars.paddingTop})`,
    paddingRight: `calc(2 * ${themeVars.paddingRight})`,
    paddingBottom: `calc(2 * ${themeVars.paddingBottom})`,
    paddingLeft: `calc(2 * ${themeVars.paddingLeft})`,
  },
  errorNote: {
    color: themeVars.errorColor,
    fontSize: themeVars.smallFontSize,
  },
  ul: {
    ":not(:last-child)": {
      marginBottom: effectiveLineHeight,
    },
  },
  ol: {
    ":not(:last-child)": {
      marginBottom: effectiveLineHeight,
    },
  },
  p: {
    ":not(:last-child)": {
      marginBottom: effectiveLineHeight,
    },
  },
  flex1: { flex: 1 },
  marginAuto: { margin: "auto" },
  marginRightAuto: { marginRight: "auto" },
  minHeight: (minHeight) => ({ minHeight }),
  width: (width) => ({ width }),
  width100Percent: { width: "100%" },
  height: (height) => ({ height }),
  positionRelative: { position: "relative" },
  textWrapBalance: { textWrap: "balance" },
  textAlignCenter: { textAlign: "center" },
  displayBlock: { display: "block" },
  displayNone: { display: "none" },
  top: (top) => ({ top }),
  bottom: (bottom) => ({ bottom }),
  left: (left) => ({ left }),
  maxWidth: (maxWidth) => ({ maxWidth }),
  maxHeight: (maxHeight) => ({ maxHeight }),
  visibilityHidden: { visibility: "hidden" },
  minWidth: (minWidth) => ({ minWidth }),
  paddingLeft: (paddingLeft) => ({ paddingLeft }),
  paddingRight: (paddingRight) => ({ paddingRight }),
  paddingTop: (paddingTop) => ({ paddingTop }),
  paddingBottom: (paddingBottom) => ({ paddingBottom }),
  marginLeft: (marginLeft) => ({ marginLeft }),
  marginRight: (marginRight) => ({ marginRight }),
  marginTop: (marginTop) => ({ marginTop }),
  background: (background) => ({ background }),
  right: (right) => ({ right }),
  borderColor: (borderColor) => ({ borderColor }),
  color: (color) => ({ color }),
});

export const themeVarsStyle = (theme: Theme) =>
  commonStyles.themeVars(
    theme.mainColor,
    theme.successColor,
    theme.backgroundColor,
    theme.alternativeBackgroundColor,
    theme.textColor,
    theme.errorColor,
    theme.warningColor,
    theme.separatorColor,
    theme.dark,
    theme.spacing,
    theme.paddingTop,
    theme.paddingRight,
    theme.paddingBottom,
    theme.paddingLeft,
    theme.maxWidth,
    theme.fontFamily,
    theme.noteColor,
    theme.fontSize,
    theme.smallFontSize,
    theme.lineHeight,
    theme.roundCornerBorderRadius,
    theme.iconSize,
    theme.weakSeparatorColor,
    theme.weakestSeparatorColor,
    theme.titleBorderSpacing,
    theme.backgroundColorRgb,
  );
