import i18next from "i18next";
import cookieConfigurations from "common/cookieConfigurations.ts";
import { createContext, useContext, useEffect, useState } from "react";
import { useCookieManager } from "./CookieManagerContext.tsx";

export type LocaleContextValue = {
  locale: string;
  setLocale: (locale: string) => void;
};

const LocaleContext = createContext<LocaleContextValue>(null!);

export const useLocale = () => useContext(LocaleContext);

export function useCreateLocaleContext(): LocaleContextValue {
  const cookieManager = useCookieManager();

  const [value, setValue] = useState(() => ({
    locale: cookieManager.get(cookieConfigurations.locale),
    setLocale: (locale: string) => {
      cookieManager.set({ ...cookieConfigurations.locale, value: locale });
      setValue((localeContext) => ({
        ...localeContext,
        locale,
      }));
      return i18next.changeLanguage(locale);
    },
  }));

  useEffect(() => {
    const language = value.locale?.split("-")[0];
    if (language) document.documentElement.lang = language;
  }, [value.locale]);

  return value;
}

export default LocaleContext;
