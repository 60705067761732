import * as stylex from "@stylexjs/stylex";

export const themeVars = stylex.defineVars({
  mainColor: null,
  successColor: null,
  backgroundColor: null,
  alternativeBackgroundColor: null,
  textColor: null,
  errorColor: null,
  warningColor: null,
  separatorColor: null,

  dark: null,
  spacing: null,
  paddingTop: null,
  paddingRight: null,
  paddingBottom: null,
  paddingLeft: null,
  maxWidth: null,
  fontFamily: null,
  noteColor: null,
  headerColor: null,
  fontSize: null,
  smallFontSize: null,
  lineHeight: null,
  roundCornerBorderRadius: null,
  iconSize: null,
  weakSeparatorColor: null,
  weakestSeparatorColor: null,
  titleBorderSpacing: null,
  backgroundColorRgb: null,
});

export const safeAreaInsetVars = stylex.defineVars({
  top: null,
  right: null,
  bottom: null,
  left: null,
});

export const virtualKeyboardVars = stylex.defineVars({
  height: null,
  transition: "100ms ease-in-out",
});
