import color from "color";
import defaultTheme from "./defaultTheme.ts";
import colorToHex from "./colorToHex.ts";

export default function createTheme({ dark }: { dark: boolean }) {
  const theme = defaultTheme[dark ? "dark" : "light"];
  const spacing = 18;
  const padding = spacing;

  return {
    ...theme,
    dark,
    spacing,
    paddingTop: padding,
    paddingRight: padding,
    paddingBottom: padding,
    paddingLeft: padding,
    maxWidth: 1100,
    fontFamily: `-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif`,
    noteColor: colorToHex(
      color(theme.textColor).mix(color(theme.backgroundColor), 0.5),
    ),
    headerColor: theme.textColor,
    fontSize: 16,
    smallFontSize: 14,
    lineHeight: 1.5,
    roundCornerBorderRadius: 4,
    iconSize: 16,
    weakSeparatorColor: colorToHex(
      color(theme.separatorColor).mix(color(theme.backgroundColor), 0.4),
    ),
    weakestSeparatorColor: colorToHex(
      color(theme.separatorColor).mix(color(theme.backgroundColor), 0.7),
    ),
    titleBorderSpacing: Math.floor(spacing / 3),
    backgroundColorRgb: color(theme.backgroundColor).rgb().array().join(" "), // Need this for CSS calculations where using hex colors is not possible
  };
}
