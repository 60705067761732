import * as Sentry from "@sentry/browser";
import { defaultLocale } from "buildConfig.ts";
import { createConfig } from "common/config.ts";
import ConfigContext from "common/ConfigContext.ts";
import cookieConfigurations from "common/cookieConfigurations.ts";
import CookieManager from "common/CookieManager.ts";
import CookieManagerContext from "common/CookieManagerContext.tsx";
import createRelayEnvironment from "common/createRelayEnvironment.ts";
import ErrorBoundary from "common/ErrorBoundary.tsx";
import initI18next from "common/initI18next.ts";
import initLogger from "common/initLogger.ts";
import logger from "common/logger.ts";
import NonceContext from "common/NonceContext.tsx";
import RouteErrorHandler from "common/RouteErrorHandler.tsx";
import RoutePrefetcherContext from "common/RoutePrefetcherContext.ts";
import SecurityManager from "common/SecurityManager.ts";
import Tracking from "common/tracking/Tracking.ts";
import TrackingContext from "common/tracking/TrackingContext.ts";
import Cookies from "js-cookie";
import { hydrateRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  matchRoutes,
} from "react-router";
import { BehaviorSubject } from "rxjs";
import createFullAppRoutes from "../common/createFullAppRoutes.tsx";

initLogger({ sentry: Sentry, breadCrumbs: true });

(async () => {
  try {
    // Fix for google translate breaking react https://bugs.chromium.org/p/chromium/issues/detail?id=872770
    // Taken from https://github.com/facebook/react/issues/11538
    if (typeof Node === "function" && Node.prototype) {
      const originalRemoveChild = Node.prototype.removeChild;
      Node.prototype.removeChild = function (child) {
        if (child.parentNode !== this) {
          if (console) {
            console.error(
              "Cannot remove a child from a different parent",
              child,
              this,
            );
          }
          return child;
        }
        return originalRemoveChild.apply(this, arguments);
      };
      const originalInsertBefore = Node.prototype.insertBefore;
      Node.prototype.insertBefore = function (newNode, referenceNode) {
        if (referenceNode && referenceNode.parentNode !== this) {
          if (console) {
            console.error(
              "Cannot insert before a reference node from a different parent",
              referenceNode,
              this,
            );
          }
          return newNode;
        }
        return originalInsertBefore.apply(this, arguments);
      };
    }

    const appInitData = JSON.parse(JSON.parse(window.appInitData));
    delete window.appInitData;

    const config = createConfig(appInitData.env);

    const cookieManager = new CookieManager({
      nullIsNoItem: false,
      get: ({ name }) => Cookies.get(name),
      set: ({ name, value, expires, path }) =>
        Cookies.set(name, value, {
          expires,
          secure: window.location.protocol === "https",
          path,
        }),
      delete: ({ name, path }) => Cookies.remove(name, { path }),
    });

    const locale =
      cookieManager.get(cookieConfigurations.locale) ||
      window.navigator.language ||
      defaultLocale;
    cookieManager.set({ ...cookieConfigurations.locale, value: locale });
    const localeSubject = new BehaviorSubject(locale);

    const nonce = cookieManager.get(cookieConfigurations.nonce);
    const tracking = new Tracking({ nonce, cookieManager });

    const securityManager = new SecurityManager({
      cookieManager,
      localeSubject,
      tracking,
      config,
    });

    const relayEnvironmentSubject = new BehaviorSubject(
      createRelayEnvironment({
        auth: securityManager.getAuth(),
        localeSubject,
        config,
      }),
    );

    securityManager.subscribeToAuth((auth) =>
      relayEnvironmentSubject.next(
        createRelayEnvironment({ auth, localeSubject, config }),
      ),
    );

    const sharer = {
      canShare: () => !!window.navigator?.share,
      share: (options) => window.navigator.share?.(options),
    };

    const routes = [
      {
        ErrorBoundary: RouteErrorHandler,
        children: [
          {
            element: (
              <NonceContext value={nonce}>
                <CookieManagerContext value={cookieManager}>
                  <Outlet />
                </CookieManagerContext>
              </NonceContext>
            ),
            children: createFullAppRoutes({
              sharer,
              userAgent: window.navigator.userAgent,
              securityManager,
              relayEnvironmentSubject,
              countryCode: appInitData.countryCode,
            }),
          },
        ],
      },
    ];

    const prefetchRoute = async (route: Parameters<typeof matchRoutes>[1]) => {
      const matches = matchRoutes(routes, route);
      if (matches)
        await Promise.all(
          matches
            .filter((m) => m.route.lazy)
            .map(async ({ route }) => {
              const promise = route.lazy();
              route.lazy = () => promise;
              Object.assign(route, {
                ...(await promise),
                lazy: undefined,
              });
            }),
        );
    };

    const [i18n] = await Promise.all([
      initI18next({ locale }),
      // (Taken from https://reactrouter.com/en/6.20.1/guides/ssr#lazy-routes)
      // Load the lazy matches and update the routes before creating your router
      // so we can hydrate the SSR-rendered content synchronously
      prefetchRoute(window.location),
    ]);

    const { errors, ...otherHydrationData } = appInitData.routerHydrationData;
    const router = createBrowserRouter(routes, {
      hydrationData: {
        ...otherHydrationData,
        errors:
          errors &&
          Object.entries(errors).reduce(
            (aggregator, [key, { error, reference }]) => {
              logger.setLinkedErrorReference(error, reference);
              aggregator[key] = error;
              return aggregator;
            },
            {},
          ),
      },
    });

    hydrateRoot(
      document.getElementById("content")!,
      <ConfigContext value={config}>
        <TrackingContext value={tracking}>
          <I18nextProvider i18n={i18n}>
            <ErrorBoundary>
              <RoutePrefetcherContext value={prefetchRoute}>
                <RouterProvider router={router} />
              </RoutePrefetcherContext>
            </ErrorBoundary>
          </I18nextProvider>
        </TrackingContext>
      </ConfigContext>,
    );
  } catch (error) {
    logger.error(error);
  }
})();
