import {
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ResizeObserver from "resize-observer-polyfill";
import deepEqual from "./deepEqual.ts";
import {
  addSafeAreaInsetsChangeListener,
  getSafeAreaInsets,
  SafeAreaInsets,
} from "./safeAreaInsets.ts";

const measurableProps = ["width", "height", "top", "right", "bottom", "left"];

export type Measurements = {
  width?: number;
  height?: number;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  safeAreaInsets?: SafeAreaInsets;
};

type Props = {
  children: (
    props: {
      ref: Ref<HTMLElement>;
    } & Measurements,
  ) => ReactNode;
  width?: boolean;
  height?: boolean;
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
  left?: boolean;
  safeAreaInsets?: boolean;
  onChange?: (props: { element: HTMLElement } & Measurements) => void;
};

export default function Measured(props: Props) {
  const { children, onChange, ...otherProps } = props;
  const [measurements, setMeasurements] = useState<Measurements>();
  const measurementsRef = useRef<Measurements>(null);
  const ref = useRef<HTMLElement>(null);

  const update = useCallback((): void => {
    const element = ref.current;
    if (!element) return;

    // Use offset* instead of getBoundingClientRect() because getBoundingClientRect takes into transforms which becomes a problem when scaling etc
    const rect = {
      width: element.offsetWidth,
      height: element.offsetHeight,
      top: element.offsetTop,
      left: element.offsetLeft,
      right: element.offsetLeft + element.offsetWidth,
      bottom: element.offsetTop + element.offsetHeight,
    };

    const newMeasurements: Measurements = {};

    measurableProps.forEach((measureProp) => {
      if (otherProps[measureProp]) {
        newMeasurements[measureProp] = rect[measureProp];
      }
    });

    if (newMeasurements.right !== undefined)
      newMeasurements.right = window.innerWidth - newMeasurements.right;
    if (newMeasurements.bottom !== undefined)
      newMeasurements.bottom = window.innerHeight - newMeasurements.bottom;

    if (otherProps.safeAreaInsets) {
      const safeAreaInsets = getSafeAreaInsets();

      newMeasurements.safeAreaInsets = {
        top: Math.max(0, safeAreaInsets.top - rect.top),
        right: Math.max(
          0,
          safeAreaInsets.right - (window.innerWidth - rect.right),
        ),
        bottom: Math.max(
          0,
          safeAreaInsets.bottom - (window.innerHeight - rect.bottom),
        ),
        left: Math.max(0, safeAreaInsets.left - rect.left),
      };
    }

    if (!deepEqual(newMeasurements, measurementsRef.current)) {
      measurementsRef.current = newMeasurements;
      setMeasurements(newMeasurements);
      onChange?.({ ...newMeasurements, element });
    }
  }, [onChange, ...Object.values(otherProps)]);

  const setRef = useCallback(
    (element: HTMLElement) => {
      ref.current = element;
      if (element) update();
    },
    [update],
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(update);
    if (ref.current) resizeObserver.observe(ref.current);
    window.addEventListener("resize", update);

    const safeAreaInsetsChangeListener = otherProps.safeAreaInsets
      ? addSafeAreaInsetsChangeListener(update)
      : null;

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener("resize", update);
      safeAreaInsetsChangeListener?.dispose();
    };
  }, [update]);

  return children({ ref: setRef, ...measurements });
}
