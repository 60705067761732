import { capacitorMode } from "buildConfig.ts";
import { ComponentProps } from "react";
import { useNavigate, useRouteError } from "react-router";
import ErrorHandler from "./ErrorHandler.tsx";

export default function RouteErrorHandler(
  props: ComponentProps<typeof ErrorHandler>,
) {
  const error = useRouteError();
  const navigate = useNavigate();
  return (
    <ErrorHandler
      error={error}
      refresh={capacitorMode ? () => navigate(0) : null}
      {...props}
    />
  );
}
