import { themeVars } from "common/variables.stylex.ts";
import { ComponentProps } from "react";
import Svg from "../Svg.tsx";

export default function CloseIcon({
  color = themeVars.textColor,
  ...otherProps
}: {
  color?: string;
} & ComponentProps<typeof Svg>) {
  return (
    <Svg viewBox="0 0 55 55" {...otherProps}>
      <path
        d="M5 5l45 45M5 50L50 5"
        fill="none"
        stroke={color}
        strokeWidth="10"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
    </Svg>
  );
}
