import { RouteObject } from "react-router";
import invitationRoute from "./InvitationTemplate/route.ts";
import newRoute from "./NewInvitationTemplate/route.ts";

export default {
  children: [
    {
      lazy: async () => ({
        ErrorBoundary: (
          await import("./InvitationTemplatesRouteErrorBoundary.tsx")
        ).default,
      }),
      children: [{ path: "new", ...newRoute }, invitationRoute],
    },
  ],
} as RouteObject;
