import { RouteObject } from "react-router";
import homeRoute from "./TestimonialsHome/route.ts";
import newRoute from "./NewTestimonial/route.ts";
import testimonialRoute from "./ManagerTestimonial/route.ts";

export default {
  children: [
    { index: true, ...homeRoute },
    {
      lazy: async () => ({
        ErrorBoundary: (await import("./TestimonialsRouteErrorBoundary.tsx"))
          .default,
      }),
      children: [{ path: "new", ...newRoute }, testimonialRoute],
    },
  ],
} as RouteObject;
