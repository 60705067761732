import colorString from "color-string";
import color from "color";

export default function colorToHex(c: ReturnType<typeof color>): string {
  return colorString.to.hex(
    Math.round(c.red()),
    Math.round(c.green()),
    Math.round(c.blue()),
  );
}
