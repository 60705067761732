import AppBase from "common/AppBase.tsx";
import { ComponentProps } from "react";
import { RouteObject } from "react-router";
import mainRoute from "../Main/route.ts";
import managerRoute from "../Manager/route.ts";
import RouteErrorHandler from "./RouteErrorHandler.tsx";

export default function createFullAppRoutes(
  props: ComponentProps<typeof AppBase>,
): RouteObject[] {
  return [
    {
      element: <AppBase {...props} />,
      children: [
        {
          ErrorBoundary: RouteErrorHandler,
          children: [
            { path: "/", ...mainRoute },
            { path: "manager", ...managerRoute },
          ],
        },
      ],
    },
  ];
}
