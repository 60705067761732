import Feature from "common/Feature.ts";
import { Trans, useTranslation } from "react-i18next";
import UpgradeSubscriptionPlanLink from "./UpgradeSubscriptionPlanLink.tsx";

export default function MissingFeatureMessage({
  feature,
  targetId,
}: {
  feature: Feature;
  targetId?: string;
}) {
  const [t] = useTranslation();

  return (
    <>
      <Trans i18nKey="MissingFeatureMessage.message" />{" "}
      <UpgradeSubscriptionPlanLink
        targetId={targetId}
        missingPermissionLabel={t(
          "MissingFeatureMessage.missingPermissionToUpgradeMessage",
        )}
      />
    </>
  );
}
