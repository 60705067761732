import { ComponentProps } from "react";
import LoadingIndicator from "./LoadingIndicator.tsx";
import { commonStyles } from "./css.ts";

export default function CenteredLoadingIndicator(
  props: ComponentProps<typeof LoadingIndicator>,
) {
  return (
    <div
      stylex={{
        width: "100%",
        height: "100%",
        display: "flex",
      }}
    >
      <LoadingIndicator style={commonStyles.marginAuto} {...props} />
    </div>
  );
}
