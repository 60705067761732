import * as stylex from "@stylexjs/stylex";
import { capacitorMode, supportEmailAddress } from "buildConfig.ts";
import { useBrowserIsNotSupported } from "common/BrowserIsNotSupportedContext.tsx";
import getUserFriendlyErrorMessage from "common/getUserFriendlyErrorMessage.ts";
import ErrorIcon from "common/icons/ErrorIcon.tsx";
import logger from "common/logger.ts";
import MissingFeatureError from "common/MissingFeatureError.ts";
import MissingFeatureMessage from "common/MissingFeatureMessage.tsx";
import { HTMLAttributes } from "react";
import { Trans, useTranslation } from "react-i18next";
import { commonStyles } from "./css.ts";
import { themeVars } from "./variables.stylex.ts";

// Must only rely on i18n context, as it can be rendered as a fallback error
export default function ErrorMessage({
  error,
  ...otherProps
}: {
  error?: any;
} & HTMLAttributes<HTMLDivElement>) {
  const [t] = useTranslation();

  let content;

  if (error?.name === MissingFeatureError.name)
    content = (
      <MissingFeatureMessage
        feature={error.feature}
        targetId={error.targetId}
      />
    );
  else {
    const userFriendlyMessage = error
      ? getUserFriendlyErrorMessage(error)
      : null;
    const browserIsNotSupported = useBrowserIsNotSupported();

    if (userFriendlyMessage) {
      content = userFriendlyMessage;
      const { cause } = error;
      const causeUserFriendlyMessage = cause
        ? getUserFriendlyErrorMessage(cause)
        : null;
      if (
        causeUserFriendlyMessage &&
        causeUserFriendlyMessage !== userFriendlyMessage
      )
        content = (
          <>
            {content}
            <br />
            {causeUserFriendlyMessage}
          </>
        );
    } else {
      const reference = logger.getErrorReference(error);

      content = (
        <>
          <ErrorIcon
            color={themeVars.noteColor}
            height={40}
            stylex={{ marginBottom: themeVars.spacing }}
          />
          <div>
            {t("ErrorMessage.intro")}
            <br />
            {browserIsNotSupported ? (
              <Trans
                i18nKey="ErrorMessage.browserIsNotSupportedMessage"
                components={{
                  updateBrowserLink: (
                    <a
                      {...stylex.props(commonStyles.a)}
                      data-test-id="update-browser-link"
                      href="https://browser-update.org/update-browser.html"
                    />
                  ),
                }}
              />
            ) : (
              t("ErrorMessage.possibleBugMessage", {
                context: capacitorMode ? "app" : "web",
              })
            )}
          </div>
          {!reference ? (
            t("ErrorMessage.contactSupportMessage", {
              supportEmailAddress,
            })
          ) : (
            <Trans
              i18nKey="ErrorMessage.contactSupportWithReferenceMessage"
              values={{
                supportEmailAddress,
                reference,
              }}
              components={{
                mailtoSupportLink: (
                  <a
                    {...stylex.props(commonStyles.a)}
                    href={`mailto:${
                      supportEmailAddress
                    }?subject=${encodeURIComponent(reference)}`}
                  />
                ),
              }}
            />
          )}
        </>
      );
    }
  }

  return (
    <div stylex={{ whiteSpace: "pre-line" }} {...otherProps}>
      {content}
    </div>
  );
}
