import { createContext, use } from "react";
import { Theme } from "./Theme.ts";

type ThemesContextValue = {
  light: Theme;
  dark: Theme;
};

const ThemesContext = createContext<ThemesContextValue>(undefined!);

export const useThemes = () => use(ThemesContext);

export default ThemesContext;
