import { graphql, fetchQuery } from "react-relay";
import { Environment } from "relay-runtime";
import logger from "common/logger.ts";
import Auth from "common/Auth.ts";
import { ScreenRecorderQuery } from "relay/ScreenRecorderQuery.graphql.ts";
import TrackingInfo from "common/tracking/TrackingInfo.ts";
import { productionMode, screenRecorderEnabled } from "buildConfig.ts";
import { Config } from "common/config.ts";

export default class ScreenRecorder {
  stopRecording?: () => any;

  private worker = new Worker(
    new URL("./ScreenRecorderWorker.ts", import.meta.url),
  );

  private relayEnvironment: Environment;

  public static isSupported(): boolean {
    return screenRecorderEnabled && productionMode;
  }

  constructor({
    relayEnvironment,
  }: {
    relayEnvironment: Environment;
    onCreated?: (props: { id: string }) => void;
  }) {
    this.relayEnvironment = relayEnvironment;
  }

  public async init(): Promise<void> {
    this.worker.onmessage = ({ data: { screenRecording } }) => {
      if (screenRecording)
        logger.setExtra("screenRecordingId", screenRecording.id);
    };
    (async () => {
      const result = await fetchQuery<ScreenRecorderQuery>(
        this.relayEnvironment,
        graphql`
          query ScreenRecorderQuery {
            screenRecordingEnabled
          }
        `,
        {},
      ).toPromise();
      if (result?.screenRecordingEnabled) {
        const { record } = await import("rrweb");
        this.stopRecording = record({
          emit: (event) => this.worker.postMessage({ event }),
        });
      }
    })();

    // Flush all events when an error occurs, so that we can replay it even if the user gives up and closes the browser.
    logger.addHandler(() => this.worker.postMessage({ command: "flush" }));
  }

  public stop(): void {
    if (this.stopRecording) this.stopRecording();
    this.worker.terminate();
  }

  public setTrackingInfo(trackingInfo: TrackingInfo): void {
    this.worker.postMessage({ trackingInfo });
  }

  public setAuth(auth: Auth | null): void {
    this.worker.postMessage({ auth });
  }

  public setConfig(config: Config): void {
    this.worker.postMessage({ config });
  }
}
