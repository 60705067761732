import { useLocation } from "react-router";
import { useCallback, useEffect, useState } from "react";
import CookieManager from "./CookieManager.ts";
import { useCookieManager } from "./CookieManagerContext.tsx";
import deepEqual from "./deepEqual.ts";

type CommonProps<T> = {
  allowedValues?: T[];
  currentPath?: boolean;
} & Omit<Parameters<InstanceType<typeof CookieManager>["set"]>[0], "value">;

export default function useCookieState<T>({
  name,
  allowedValues,
  contentType,
  currentPath,
  defaultValue,
  ...otherProps
}: CommonProps<T> & { defaultValue: T }): [
  T,
  React.Dispatch<React.SetStateAction<T>>,
];

export default function useCookieState<T>({
  name,
  allowedValues,
  contentType,
  currentPath,
  defaultValue,
  ...otherProps
}: CommonProps<T> & { defaultValue?: T }): [
  T | undefined,
  React.Dispatch<React.SetStateAction<T | undefined>>,
];

export default function useCookieState<T>({
  name,
  allowedValues,
  contentType,
  currentPath,
  defaultValue,
  ...otherProps
}: CommonProps<T> & { defaultValue?: T }) {
  const location = useLocation();
  const cookieManager = useCookieManager();
  const path = currentPath ? location.pathname : otherProps.path;

  const getActualValue = (cookieValue: any) =>
    (!allowedValues
      ? cookieValue
      : allowedValues.find((v) => deepEqual(v, cookieValue))) ?? defaultValue;

  const [value, setValue] = useState<T>(() =>
    getActualValue(cookieManager.get({ name, contentType, path })),
  );

  useEffect(() => {
    const subscription = cookieManager.subscribe({
      name,
      subscriber: (v) => setValue(getActualValue(v)),
    });
    return () => subscription.unsubscribe();
  }, [name, cookieManager]);

  return [
    value,
    useCallback(
      (newValue: T | ((oldValue: T) => T)) =>
        cookieManager.set({
          ...otherProps,
          name,
          value:
            typeof newValue === "function"
              ? (newValue as (oldValue: T) => T)(value)
              : newValue,
          contentType,
          path,
        }),
      [cookieManager, value],
    ),
  ];
}
