import * as stylex from "@stylexjs/stylex";
import "global.css";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import { Environment } from "relay-runtime";
import { BehaviorSubject, skip } from "rxjs";
import AppMeasurementsContext from "./AppMeasurementsContext.tsx";
import BrowserIsNotSupportedContext from "./BrowserIsNotSupportedContext.tsx";
import CenteredLoadingIndicator from "./CenteredLoadingIndicator.tsx";
import { useConfig } from "./ConfigContext.ts";
import cookieConfigurations from "./cookieConfigurations.ts";
import CookieManagerContext, {
  useCookieManager,
} from "./CookieManagerContext.tsx";
import CountryCodeContext from "./CountryCodeContext.ts";
import createTheme from "./createTheme.ts";
import { commonStyles, stringifyCss, virtualKeyboardVarsCss } from "./css.ts";
import DarkModeContext, {
  useCreateDarkModeContext,
} from "./DarkModeContext.ts";
import LinkHandlerContext, { LinkHandler } from "./LinkHandlerContext.tsx";
import LocaleContext, { useCreateLocaleContext } from "./LocaleContext.tsx";
import Measured from "./Measured.tsx";
import MobileModeContext, {
  useCreateMobileModeContext,
} from "./MobileModeContext.tsx";
import Notification from "./Notification.tsx";
import NotificationsContext, {
  useCreateNotificationsContext,
} from "./NotificationsContext.tsx";
import SafeAreaInsetsContext from "./SafeAreaInsetsContext.tsx";
import ScreenRecorder from "./ScreenRecorder/index.ts";
import SecurityContext, {
  useCreateSecurityContext,
} from "./SecurityContext.tsx";
import SecurityManager from "./SecurityManager.ts";
import SharerContext, { Sharer } from "./SharerContext.tsx";
import ThemeProvider from "./ThemeProvider.tsx";
import ThemesContext from "./ThemesContext.tsx";
import { useTracking } from "./tracking/TrackingContext.ts";
import useTrackingInfo from "./tracking/useTrackingInfo.ts";
import useConsent from "./useConsent.tsx";
import UserAgentContext from "./UserAgentContext.tsx";
import { themeVars } from "./variables.stylex.ts";
import {
  useVirtualKeyboard,
  VirtualKeyboardStyle,
} from "./VirtualKeyboardContext.tsx";

const styles = stylex.create({
  base: { height: "100%" },
});

const defaultThemes = {
  light: createTheme({ dark: false }),
  dark: createTheme({ dark: true }),
};

export default function AppBase({
  relayEnvironmentSubject,
  sharer,
  userAgent,
  defaultMobileMode,
  defaultDarkMode,
  linkHandler: linkHandlerProp,
  securityManager,
  countryCode,
}: {
  relayEnvironmentSubject: BehaviorSubject<Environment>;
  sharer?: Sharer;
  userAgent?: string;
  defaultMobileMode?: boolean;
  defaultDarkMode?: boolean;
  linkHandler?: LinkHandler;
  securityManager: SecurityManager;
  countryCode: string;
}) {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const cookieManager = useCookieManager();
  const previousNotificationsRef = useRef<Notification[]>(null);
  const screenRecorderRef = useRef<ScreenRecorder>(null);
  const darkMode = useCreateDarkModeContext({ defaultDarkMode });
  const mobileMode = useCreateMobileModeContext({ defaultMobileMode });
  const locale = useCreateLocaleContext();
  const security = useCreateSecurityContext({ securityManager });
  const notifications = useCreateNotificationsContext();
  const [consent] = useConsent();
  const tracking = useTracking();
  const trackingInfo = useTrackingInfo();
  const config = useConfig();
  const virtualKeyboard = useVirtualKeyboard();

  const [relayEnvironment, setRelayEnvironment] = useState(
    relayEnvironmentSubject.getValue(),
  );
  useEffect(() => {
    const subscriber = relayEnvironmentSubject
      .pipe(skip(1))
      .subscribe(setRelayEnvironment);
    return () => subscriber.unsubscribe();
  }, [relayEnvironmentSubject]);

  useEffect(() => {
    tracking.init({ consent });

    if (ScreenRecorder.isSupported()) {
      const screenRecorder = new ScreenRecorder({ relayEnvironment });
      screenRecorder.init();
      screenRecorderRef.current = screenRecorder;

      return () => {
        screenRecorder?.stop();
        darkMode.destroy?.();
      };
    }
    return undefined;
  }, []);

  useEffect(() => {
    tracking.setConsent(consent);
  }, [consent]);

  useEffect(() => screenRecorderRef.current?.setConfig(config), [config]);
  useEffect(
    () => screenRecorderRef.current?.setAuth(security?.auth || null),
    [security?.auth],
  );
  useEffect(() => {
    screenRecorderRef.current?.setTrackingInfo(trackingInfo);
  }, [trackingInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (previousNotificationsRef.current)
      notifications.dismissNotifications(
        previousNotificationsRef.current.filter((n) => !n.autoHide),
      );
    previousNotificationsRef.current = notifications.notifications;
  }, [location.pathname]);

  useEffect(() => {
    tracking.setLocation({ location, params });
  }, [location]);

  const theme = defaultThemes[darkMode.darkMode ? "dark" : "light"];
  useEffect(() => {
    virtualKeyboard.setStyle(
      theme.dark ? VirtualKeyboardStyle.Dark : VirtualKeyboardStyle.Light,
    );
  }, [theme.dark]);

  const linkHandler = useMemo(
    () =>
      linkHandlerProp || {
        navigate: ({ to, target }) => {
          if (target && target !== "_self") window.open(to, target);
          else if (to.startsWith("http://") || to.startsWith("https://"))
            window.location.href = to;
          else navigate(to);
        },
      },
    [linkHandlerProp, navigate],
  );

  const browserIsNotSupported = cookieManager.get(
    cookieConfigurations.browserIsNotSupported,
  );

  return (
    <CountryCodeContext value={countryCode}>
      <RelayEnvironmentProvider environment={relayEnvironment}>
        <MobileModeContext value={mobileMode}>
          <SharerContext value={sharer}>
            <CookieManagerContext value={cookieManager}>
              <BrowserIsNotSupportedContext value={browserIsNotSupported}>
                <LocaleContext value={locale}>
                  <LinkHandlerContext value={linkHandler}>
                    <SecurityContext value={security}>
                      <NotificationsContext value={notifications}>
                        <UserAgentContext value={userAgent}>
                          <DarkModeContext value={darkMode}>
                            <ThemesContext value={defaultThemes}>
                              <ThemeProvider dark={darkMode.darkMode}>
                                <ScrollRestoration />

                                <meta charSet="UTF-8" />
                                <meta
                                  httpEquiv="X-UA-Compatible"
                                  content="IE=edge,chrome=1"
                                />
                                <meta
                                  name="viewport"
                                  content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, viewport-fit=cover"
                                />
                                <meta name="referrer" content="origin" />
                                <meta
                                  name="apple-mobile-web-app-capable"
                                  content="yes"
                                />
                                <meta
                                  name="mobile-web-app-capable"
                                  content="yes"
                                />
                                <meta
                                  name="apple-mobile-web-app-status-bar-style"
                                  content="black-translucent"
                                />
                                <meta
                                  name="msapplication-tap-highlight"
                                  content="no"
                                />
                                <link rel="manifest" href="/manifest.json" />
                                <link rel="icon" href="/favicon.ico" />
                                <link
                                  rel="icon"
                                  type="image/svg+xml"
                                  href="/images/favicon.svg"
                                />

                                <style>
                                  {`
                                    :root {
                                      ${stringifyCss(virtualKeyboardVarsCss({ height: (virtualKeyboard?.futureHeight ?? virtualKeyboard?.height) || 0 }))}
                                    }
                                  `}
                                </style>

                                <Measured width height safeAreaInsets>
                                  {({
                                    ref: measureRef,
                                    ...appMeasurements
                                  }) => (
                                    <div
                                      ref={measureRef}
                                      {...stylex.props(
                                        styles.base,
                                        appMeasurements.safeAreaInsets &&
                                          commonStyles.safeAreaInsetVars(
                                            appMeasurements.safeAreaInsets,
                                          ),
                                      )}
                                    >
                                      <AppMeasurementsContext
                                        value={appMeasurements}
                                      >
                                        <SafeAreaInsetsContext
                                          value={appMeasurements.safeAreaInsets}
                                        >
                                          <Suspense
                                            fallback={
                                              <CenteredLoadingIndicator />
                                            }
                                          >
                                            <Outlet />

                                            <div id="hoverBoxContents" />

                                            {notifications.notifications
                                              ?.length > 0 && (
                                              <div
                                                stylex={{
                                                  position: "fixed",
                                                  top: "50%",
                                                  left: "50%",
                                                  transform:
                                                    "translate(-50%, -50%)",
                                                  maxWidth: 600,
                                                  paddingLeft:
                                                    themeVars.paddingLeft,
                                                  paddingRight:
                                                    themeVars.paddingRight,
                                                  marginRight: "auto",
                                                  marginLeft: "auto",
                                                  width: "100%",
                                                  maxHeight: "100%",
                                                  overflow: "auto",
                                                  boxSizing: "border-box",
                                                  textAlign: "center",
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  gap: themeVars.spacing,
                                                }}
                                              >
                                                {notifications.notifications
                                                  .reduce(
                                                    (
                                                      accumulator,
                                                      notification,
                                                    ) => {
                                                      let addedToGroup = false;
                                                      for (
                                                        let accumulatorLength =
                                                            accumulator.length,
                                                          i = 0;
                                                        i < accumulatorLength;
                                                        i += 1
                                                      ) {
                                                        const notificationInAccumulator =
                                                          Array.isArray(
                                                            accumulator[i],
                                                          )
                                                            ? accumulator[i][0]
                                                            : accumulator[i];

                                                        if (
                                                          (notification.message ===
                                                            notificationInAccumulator.message ||
                                                            (notification.error
                                                              ?.message &&
                                                              notification.error
                                                                ?.message ===
                                                                notificationInAccumulator
                                                                  .error
                                                                  ?.message)) &&
                                                          notification.autoHide ===
                                                            notificationInAccumulator.autoHide
                                                        ) {
                                                          if (
                                                            Array.isArray(
                                                              accumulator[i],
                                                            )
                                                          )
                                                            accumulator[i].push(
                                                              notification,
                                                            );
                                                          else
                                                            accumulator[i] = [
                                                              accumulator[i],
                                                              notification,
                                                            ];
                                                          addedToGroup = true;
                                                          break;
                                                        }
                                                      }
                                                      if (!addedToGroup)
                                                        accumulator.push(
                                                          notification,
                                                        );
                                                      return accumulator;
                                                    },
                                                    [],
                                                  )
                                                  .map(
                                                    (
                                                      notificationOrNotifications,
                                                    ) => {
                                                      const array =
                                                        Array.isArray(
                                                          notificationOrNotifications,
                                                        );
                                                      return (
                                                        <Notification
                                                          data-test-id="notification"
                                                          key={
                                                            (array
                                                              ? notificationOrNotifications[
                                                                  notificationOrNotifications.length -
                                                                    1
                                                                ]
                                                              : notificationOrNotifications
                                                            ).id
                                                          }
                                                          notification={
                                                            array
                                                              ? undefined
                                                              : notificationOrNotifications
                                                          }
                                                          notifications={
                                                            array
                                                              ? notificationOrNotifications
                                                              : undefined
                                                          }
                                                        />
                                                      );
                                                    },
                                                  )}
                                              </div>
                                            )}
                                          </Suspense>
                                        </SafeAreaInsetsContext>
                                      </AppMeasurementsContext>
                                    </div>
                                  )}
                                </Measured>
                              </ThemeProvider>
                            </ThemesContext>
                          </DarkModeContext>
                        </UserAgentContext>
                      </NotificationsContext>
                    </SecurityContext>
                  </LinkHandlerContext>
                </LocaleContext>
              </BrowserIsNotSupportedContext>
            </CookieManagerContext>
          </SharerContext>
        </MobileModeContext>
      </RelayEnvironmentProvider>
    </CountryCodeContext>
  );
}
