import { fromByteArray, toByteArray } from "base64-js";

// btoa doesn't support unicode so we need to work around it
export function base64Encode(input: string | Uint8Array) {
  return fromByteArray(
    input instanceof String || typeof input === "string"
      ? new TextEncoder().encode(input as string)
      : input,
  );
}

export function base64ToByteArray(data: string) {
  let actualData = data;
  while (actualData.length % 4) actualData += "=";
  return toByteArray(actualData);
}

export function base64ToUrlSafe(string: string) {
  return string.replaceAll("+", "-").replaceAll("/", "_").replaceAll("=", "");
}

export function base64FromUrlSafe(string: string) {
  return string.replaceAll("-", "+").replaceAll("_", "/");
}
