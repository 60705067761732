import getLazyRouteProps from "common/getLazyRouteProps.ts";
import { redirect, RouteObject } from "react-router";
import detailsRoute from "./InvitationTemplateDetails/route.ts";
import permissionsRoute from "./InvitationTemplatePermissions/route.ts";
import deleteRoute from "./DeleteInvitationTemplate/route.ts";

export default {
  path: ":invitationTemplateId",
  lazy: () => getLazyRouteProps(import("./index.tsx")),
  children: [
    /* If we needed a home route, it would be here. Using the invitations home for templates instead. */
    {
      lazy: async () => ({
        ErrorBoundary: (
          await import("./InvitationTemplateRouteErrorBoundary.tsx")
        ).default,
      }),
      children: [
        { index: true, loader: () => redirect("details") },
        { path: "details", ...detailsRoute },
        { path: "permissions", ...permissionsRoute },
        { path: "delete", ...deleteRoute },
      ],
    },
  ],
} as RouteObject;
