import * as stylex from "@stylexjs/stylex";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "common/Button/index.tsx";
import { commonStyles } from "./css.ts";
import ErrorMessage from "./ErrorMessage.tsx";
import {
  Notification as NotificationType,
  NotificationType as Type,
  useNotifications,
} from "./NotificationsContext.tsx";
import { themeVars } from "./variables.stylex.ts";

const styles = stylex.create({
  base: {
    borderRadius: themeVars.roundCornerBorderRadius,
    paddingTop: themeVars.paddingTop,
    paddingRight: themeVars.paddingRight,
    paddingBottom: themeVars.paddingBottom,
    paddingLeft: themeVars.paddingLeft,
    background: themeVars.alternativeBackgroundColor,
    overflow: "hidden",
    boxSizing: "border-box",
    transition: "opacity 0.5s",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: themeVars.spacing,
  },
  compact: {
    paddingTop: `calc(${themeVars.paddingTop} / 2)`,
    paddingRight: `calc(${themeVars.paddingRight} / 2)`,
    paddingBottom: `calc(${themeVars.paddingBottom} / 2)`,
    paddingLeft: `calc(${themeVars.paddingLeft} / 2)`,
  },
  dismissing: {
    opacity: 0,
  },
  counter: {
    position: "absolute",
    top: themeVars.spacing,
    left: themeVars.spacing,
  },
});

const typeStyles = stylex.create({
  error: {
    background: themeVars.backgroundColor,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: themeVars.errorColor,
  },
  warning: {
    background: themeVars.backgroundColor,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: themeVars.warningColor,
  },
});

export default function Notification({
  children,
  notification: propsNotification,
  notifications,
  error,
  type: propsType,
  compact,
  onClose,
  style,
}: {
  children?: ReactNode;
  notification?: NotificationType;
  notifications?: NotificationType[];
  error?: any;
  type?: Type;
  compact?: boolean;
  onClose?: () => void;
  style?: stylex.StyleXStyles;
}) {
  const { dismissNotifications } = useNotifications();
  const [dismissing, setDismissing] = useState<boolean>();
  const [t] = useTranslation();

  const notification = propsNotification || (notifications && notifications[0]);

  const dismiss = () => dismissNotifications(notifications || [notification]);

  useEffect(() => {
    if (notification?.autoHide) {
      const timeout = 2000;
      const start = new Date().getTime();
      const cleanupHandlers: (() => void)[] = [];

      const dismissHandler = () => {
        setDismissing(true);
        cleanupHandlers.forEach((h) => h());
      };

      const timeoutRef = setTimeout(dismissHandler, timeout);
      cleanupHandlers.push(() => clearTimeout(timeoutRef));

      window.addEventListener("mousemove", dismissHandler, { once: true });
      cleanupHandlers.push(() =>
        window.removeEventListener("mousemove", dismissHandler),
      );

      return () => {
        cleanupHandlers.forEach((h) => h());
        if (new Date().getTime() - start >= timeout) dismissHandler();
      };
    }
    return undefined;
  }, []);

  let type = propsType;
  let content;

  if (children) content = children;
  else if (error) {
    type = "error";
    content = <ErrorMessage error={error} />;
  } else if (notification) {
    const { message, error } = notification;
    type = notification.type;

    if (message) content = message;
    else if (error) content = <ErrorMessage error={error} />;
  }

  const showCloseButton = (notification && !notification.autoHide) || onClose;

  return (
    <div
      {...stylex.props(
        styles.base,
        typeStyles[type],
        compact && styles.compact,
        dismissing && styles.dismissing,
        style,
      )}
      onTransitionEnd={dismiss}
    >
      {!!notifications?.length && (
        <div {...stylex.props(commonStyles.note, styles.counter)}>
          {notifications.length}x
        </div>
      )}
      <span stylex={{ verticalAlign: "middle", whiteSpace: "pre-line" }}>
        {content}
      </span>
      {showCloseButton && (
        <Button variant="naked" onClick={onClose || dismiss}>
          {t("Notification.closeButtonLabel")}
        </Button>
      )}
    </div>
  );
}
