import { HTMLAttributes } from "react";
import Measured, { Measurements } from "./Measured.tsx";

/**
 * Don't use variable padding in the css of this component (wrap something instead). Because:
 * "It's important to note that while ResizeObserver reports both the dimensions of the contentRect and the padding, it only watches the contentRect." (https://web.dev/resize-observer/)
 *
 * Hint: Remember to use overflow: hidden if you are setting the size of a child, so that the child does not prevent this div from shrinking and properly resizing
 */

export default function MeasuredDiv({
  width,
  height,
  top,
  right,
  bottom,
  left,
  safeAreaInsets,
  children,
  onChange,
  ...otherProps
}: {
  width?: boolean;
  height?: boolean;
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
  left?: boolean;
  safeAreaInsets?: boolean;
  children: ((props: Measurements) => any) | any;
  onChange?: (
    props: Partial<{
      width?: number;
      height?: number;
      top?: number;
      right?: number;
      bottom?: number;
      left?: number;
    }>,
  ) => void;
} & Omit<HTMLAttributes<HTMLDivElement>, "children">) {
  return (
    <Measured
      width={width}
      height={height}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      safeAreaInsets={safeAreaInsets}
      onChange={onChange}
    >
      {({ ref, ...measuredProps }) => (
        <div ref={ref} {...otherProps}>
          {typeof children === "function" ? children(measuredProps) : children}
        </div>
      )}
    </Measured>
  );
}
