import AuthenticationError from "common/AuthenticationError.ts";
import AuthorizationError from "common/AuthorizationError.ts";
import Button from "common/Button/index.tsx";
import ErrorMessage from "common/ErrorMessage.tsx";
import logger from "common/logger.ts";
import { useSecurity } from "common/SecurityContext.tsx";
import { HtmlHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { useConfig } from "./ConfigContext.ts";
import { themeVars } from "./variables.stylex.ts";

export default function ErrorHandler({
  error,
  extras,
  retry,
  refresh,
  ...otherProps
}: {
  error?: any;
  extras?: any;
  retry?: () => void;
  refresh?: () => void;
} & HtmlHTMLAttributes<HTMLDivElement>) {
  const config = useConfig();
  const security = useSecurity();
  const [t] = useTranslation();

  const canLogOut =
    (error?.name === AuthenticationError.className ||
      error?.name === AuthorizationError.className) &&
    security?.logOut;

  return (
    <div {...otherProps}>
      <div
        stylex={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <ErrorMessage error={logger.error(error, { extras })} />
        {(retry || refresh || canLogOut) && (
          <div
            stylex={{
              marginTop: themeVars.spacing,
              display: "flex",
              gap: `calc(2 * ${themeVars.spacing})`,
            }}
          >
            {refresh && (
              <Button variant="naked" onClick={refresh}>
                {t("ErrorHandler.refreshButtonLabel")}
              </Button>
            )}
            {retry && (
              <Button variant="naked" onClick={retry}>
                {t("ErrorHandler.retryButtonLabel")}
              </Button>
            )}
            {canLogOut && (
              <Button
                variant="naked"
                onClick={() => security.logOut()}
                to={config.logInPath}
              >
                {t("ErrorHandler.logOutButtonLabel")}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
