import * as stylex from "@stylexjs/stylex";
import { ComponentProps } from "react";

export default function Svg({
  style,
  ...otherProps
}: {
  style?: stylex.StyleXStyles;
} & Omit<ComponentProps<"svg">, "style">) {
  return <svg {...stylex.props(style)} {...otherProps} />;
}
