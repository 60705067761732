import safeAreaInsets from "safe-area-insets";

export type SafeAreaInsets = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export function getSafeAreaInsets() {
  return safeAreaInsets;
}

export type Disposable = {
  dispose: () => void;
};

export function addSafeAreaInsetsChangeListener(
  callback: (safeAreaInsets: SafeAreaInsets) => void,
): Disposable {
  const listener = () => {
    const insets = getSafeAreaInsets();
    if (insets) callback(insets);
  };
  safeAreaInsets.onChange(listener);
  return {
    dispose: () => {
      safeAreaInsets.offChange(listener);
    },
  };
}
