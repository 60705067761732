import i18next from "i18next";
import { GraphIdType, getGraphIdTypename } from "./GraphId.ts";
import UserFriendlyMessageError, {
  Options as ParentOptions,
} from "./UserFriendlyMessageError.ts";

export type Options = {
  key?: any;
  graphIdType?: GraphIdType;
  [key: string]: any;
} & ParentOptions;

export default class NotFoundError extends UserFriendlyMessageError {
  public static className = "NotFoundError";

  public readonly key?: any;

  public readonly graphIdType?: GraphIdType;

  constructor(messageOrOptions?: string | Options, options?: Options) {
    const { t } = i18next;
    const message =
      typeof messageOrOptions === "string" ? messageOrOptions : "Not found.";
    const { key, graphIdType, userFriendlyMessage } =
      (typeof messageOrOptions === "object" && messageOrOptions) ||
      options ||
      {};

    let actualUserFriendlyMessage = userFriendlyMessage;
    if (actualUserFriendlyMessage === undefined) {
      const typename = graphIdType && getGraphIdTypename(graphIdType, t);
      actualUserFriendlyMessage = typename
        ? t("NotFoundError.graphIdTypeUserFriendlyMessage", {
            context: typename,
            defaultValue: t("NotFoundError.userFriendlyMessage", {
              defaultValue: message,
            }),
          })
        : t("NotFoundError.userFriendlyMessage", {
            defaultValue: message,
          });
    }

    super(message, { userFriendlyMessage: actualUserFriendlyMessage });
    this.name = NotFoundError.className;
    this.key = key;
    this.graphIdType = graphIdType;
  }
}
